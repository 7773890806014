import './ContactUs.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { CardContent, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import Whatsapp from '../../assets/svg/whatsapp.svg';
import ContactForm from '../../components/ContactForm/ContactForm';

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box mt={17} mb={35}>
      <Container maxWidth="lg" className="contact-us-container">
        <div className="contact-us-container">
          <Box variant="outlined" className="contact-us-card">
            <CardContent>
              <ContactForm />
            </CardContent>
          </Box>
          <Box
            className="contact-us-card-contact-info"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
            }}
          >
            <Box
              variant="outlined"
              className="contact-us-card contact-us-card-image"
            ></Box>
            <Box
              sx={{
                width: '100%',
                padding: '30px',
                borderRadius: '20px',
              }}
              variant="outlined"
            >
              <Box mb={2} className="contact-info-container">
                <FontAwesomeIcon
                  className="contact-info-container-icon"
                  color="#fff"
                  icon={faEnvelope}
                />
                <Box>
                  <Typography>Email</Typography>
                  <a
                    className="contactus-email"
                    href="mailto:ask@webfluid.studio?subject=Inquiry%20from%20Website:%20Seeking%20Webfluid%20Studio's%20Expert%20Services"
                    rel="noreferrer"
                    target="_blank"
                  >
                    ask@webfluid.studio
                  </a>
                </Box>
              </Box>
              <Box mb={2} className="contact-info-container">
                <FontAwesomeIcon
                  className="contact-info-container-icon"
                  color="#fff"
                  icon={faPhone}
                />
                <Box>
                  <Typography>Phone</Typography>
                  <Typography>
                    <a
                      id="company-phone-number"
                      href="tel:786-856-2000"
                      rel="noreferrer"
                    >
                      786 856 2000
                    </a>
                  </Typography>
                </Box>
              </Box>
              <Box className="contact-info-container">
                <Box
                  id="whatsapp-icon-container"
                  className="contact-info-container-icon"
                >
                  <img id="whatsapp-icon" alt="Whatsapp logo" src={Whatsapp} />
                </Box>
                <Box>
                  <Typography>WhatsApp</Typography>
                  <Typography>
                    <a
                      id="whatsapp-link"
                      href="https://api.whatsapp.com/send?phone=7868562000"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Chat With Us
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </Container>
    </Box>
  );
}

export default ContactUs;
