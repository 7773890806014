import './App.css';
import { useEffect, useState, useCallback } from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Whatsapp from './assets/svg/whatsapp.svg';

// Views
import Home from './views/Home/Home';
import Services from './views/Services/Services';
import AboutUs from './views/AboutUs/AboutUs';
import Footer from './components/Footer/Footer';
import ContactUs from './views/ContactUs/ContactUs';
import CaseStudies from './views/CaseStudies/CaseStudies';
import ServiceView from './views/ServiceView/ServiceView';

// Components
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CaseStudyView from './views/CaseStudyView/CaseStudyView';
import Modal from '@mui/material/Modal';
import WelcomeModal from './components/WelcomeModal/WelcomeModal';
import Fab from '@mui/material/Fab';

function App() {
  const [scroll, setScroll] = useState(true);
  const [scrollY, setScrollY] = useState(true);
  const [appBarFixed, setAppBarFixed] = useState(true);
  const [height, setHeight] = useState(660);
  const [mobileView, setMobileView] = useState(false);
  const [allowInvisableHeader, setAllowInvisableHeader] = useState(true);
  const [whiteHeader, setWhiteHeader] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const location = useLocation();
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < scrollY && !appBarFixed && location.pathname.replace(/[0-9]/g, '') !== '/') {
        setAppBarFixed(true);
      } else if (window.scrollY > scrollY && appBarFixed) {
        setAppBarFixed(false);
      }
      setScrollY(window.scrollY);
      setScroll(window.scrollY < height - 240);
    });
    window.addEventListener('resize', () => {
      setScroll(window.scrollY < height - 60);
      if (window.innerWidth < 960 && !mobileView) {
        setMobileView(true);
      } else if (window.innerWidth >= 960 && mobileView) {
        setMobileView(false);
      }
    });
  }, [height, location, mobileView, scrollY, appBarFixed]);

  useEffect(() => {
    const pathName = location.pathname.replace(/[0-9]/g, '');
    const invisibleHeaderRoutes = ['/'];
    const whiteHeaderRoutes = ['/aboutUs', '/services', '/services/', '/caseStudies', '/caseStudies/', '/blog', '/blog/', '/contactUs'];
    setAllowInvisableHeader(invisibleHeaderRoutes.includes(pathName));
    setWhiteHeader(whiteHeaderRoutes.includes(pathName));
  }, [location]);

  useEffect(() => {
    let visited = localStorage['alreadyVisited'];
    if (!visited) {
      localStorage['alreadyVisited'] = true;

      //this is the first time
      setTimeout(() => {
        handleOpen(true);
        localStorage['alreadyVisited'] = true;
      }, 30000);
    }
  });

  const gatedSetHeight = useCallback((value) => {
    setHeight(value);
  }, []);

  const toggleDrawer = (val) => {
    setOpen(val);
  };

  const handleOpen = () => setOpenModal(true);
  const handleClose = (event, reason) => {
    setOpenModal(false);
  };
  return (
    <div className="App">
      <AppBar
        position={appBarFixed ? 'fixed' : 'absolute'}
        className={'app-bar ' + (scroll && allowInvisableHeader ? 'invisable-header' : '') + (whiteHeader ? 'white-header' : '')}
      >
        <Toolbar className="app-bar">
          <div className="app-bar-content">
            <Typography id="logo-text" variant="h5" color="inherit" component={Link} to="/" sx={{ flexGrow: 1 }}>
              <span className="normal-text">Webfluid Studio</span>
              <span className="small-text">Webfluid</span>
            </Typography>
            <div className="desktop-menu-items">
              <Button className="navigation-buttons" color="inherit" component={Link} to="/aboutUs">
                About Us
              </Button>
              <Button className="navigation-buttons" color="inherit" component={Link} to="/services">
                Services
              </Button>
              <Button className="navigation-buttons" color="inherit" component={Link} to="/caseStudies">
                Our Clients
              </Button>
              {/* <Button
                className="navigation-buttons"
                color="inherit"
                component={Link}
                to="/blog"
              >
                Blog
              </Button> */}
              <Button
                className={'desktop-menu-items contact-us-button ' + (whiteHeader ? 'contact-us-button-blue' : '')}
                color="warning"
                variant={whiteHeader ? 'outlined' : 'contained'}
                component={Link}
                to="/contactUs"
              >
                Contact us
                <span className="contact-us-extra">or Call (786) 540-8851</span>
              </Button>
            </div>
            <Button
              className="hamburger-menu"
              id="composition-button"
              aria-controls={open ? 'composition-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={() => toggleDrawer(true)}
            >
              <FontAwesomeIcon color={whiteHeader ? 'black' : 'white'} size="2x" icon={faBars} />
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <Routes>
        <Route path="/" element={<Home height={height} setHeight={gatedSetHeight} />} />
        <Route path="/services" element={<Services height={height} setHeight={gatedSetHeight} />} />
        <Route path="/aboutUs" element={<AboutUs height={height} setHeight={gatedSetHeight} />} />
        <Route path="/contactUs" element={<ContactUs />} />
        {/* <Route path="/blog" element={<Blog />} /> */}
        <Route path="/CaseStudies" element={<CaseStudies height={height} setHeight={gatedSetHeight} />} />
        <Route path="/CaseStudies/:id" element={<CaseStudyView />} />
        <Route path="/Services/:id" element={<ServiceView />} />
        {/* <Route path="/blog/:id" element={<BlogPost />} /> */}
      </Routes>
      <Footer />
      <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>
        <ListItem disablePadding>
          <ListItemButton component={Link} onClick={() => toggleDrawer(false)} to="/contactUs">
            <Button color="warning" variant="contained" tabIndex={-1}>
              <ListItemText> Contact Us</ListItemText>
            </Button>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} onClick={() => toggleDrawer(false)} to="/aboutUs">
            <ListItemText> About Us</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} onClick={() => toggleDrawer(false)} to="/services">
            <ListItemText>Services </ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton component={Link} onClick={() => toggleDrawer(false)} to="/caseStudies">
            <ListItemText> Our Clients</ListItemText>
          </ListItemButton>
        </ListItem>

        {/* <ListItem disablePadding>
          <ListItemButton
            component={Link}
            onClick={() => toggleDrawer(false)}
            to="/blog"
          >
            <ListItemText>Blog </ListItemText>
          </ListItemButton>
        </ListItem> */}
      </Drawer>
      <Modal onOverlayClick={false} open={openModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <WelcomeModal setOpenModal={setOpenModal} />
      </Modal>
      <Fab id="whatsapp-fab" color="primary" aria-label="Whatsapp" href="https://api.whatsapp.com/send?phone=7868562000" rel="noreferrer" target="_blank">
        <img id="whatsapp-icon" alt="Whatsapp logo" src={Whatsapp} />
      </Fab>
    </div>
  );
}

export default App;
