import { Box } from '@mui/system';
import './Testimonial.css';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function Testimonial({ testimonial }) {
  return (
    <Card id="testimonial-container">
      <CardContent id="testimonial-content">
        <img id="testimonial-image" src={testimonial.image} alt={testimonial.name} />
        <Typography id="testimonial-title" sx={{ typography: { xs: 'h5' } }}>
          {testimonial.name}
        </Typography>
        <Typography id="testimonial-description" sx={{ typography: { xs: 'body1' } }}>
          <Typography className="mb-3">⭐️⭐️⭐️⭐️⭐️</Typography>
          {testimonial.testimonial}
        </Typography>
      </CardContent>
      <Box id="testimonial-actions">
        <Button id="testimonial-action" component={Link} to="/caseStudies" color="warning" variant="contained" size="medium">
          learn more
        </Button>
      </Box>
    </Card>
  );
}

export default Testimonial;
